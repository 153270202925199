@import "./variables.scss";
.admin-header {
  background-color: $theme_background_color;
  position: relative;
  display: flex;
  width: 100%;
  height: 50px;
  justify-content: flex-start;
  // padding-left: 50px;
  align-items: center;
  .btn-section {
    display: flex;
    // svg {
    margin: 30px 2px 30px 0px;
    // }
    svg {
      font-size: large;
      margin-top: 3px;
    }
    .arrow-section {
      font-size: large;
      margin-left: 20px;

      // &:hover {
      //   background-color: aqua;
      //   padding: 20px;
      // }
    }
    &:hover {
      cursor: pointer;
    }
  }
}
