@import "./variables.scss";
.custom-btn {
  padding: 17px 40px;
  border-radius: 50px;
  border: 0;
  background-color: $primary_color;
  color: white;
  box-shadow: rgb(0 0 0 / 5%) 0 0 8px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  font-size: 15px;
  transition: all 0.5s ease;
  margin-top: 20px;

  &:hover {
    letter-spacing: 3px;
    background-color: $secondary_color;
    color: hsl(0, 0%, 100%);
    box-shadow: $secondary_color 0px 7px 29px 0px;
  }

  &:active {
    letter-spacing: 3px;
    background-color: $secondary_color;
    color: hsl(0, 0%, 100%);
    box-shadow: $secondary_color 0px 0px 0px 0px;
    transform: translateY(10px);
    transition: 100ms;
  }
}

.w-67 {
  width: 67%;
}
.w-100-margin-20-right {
  width: 97%;
}
.w-fit {
  width: fit-content;
}
