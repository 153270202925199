.medelMainWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  //   left: 50%;
  //   top: 50%;
  //   transform: translate(-50%, -50%);
  //background-color: transparent;
  background-color: rgba(0, 0, 0, 0.5);

  .modelWrapper {
    display: flex;
    flex-direction: column;

    gap: 20px;
    width: 400px;

    padding: 20px;
    background-color: #fefefe;

    border: 1px solid #888;

    border-radius: 8px;
    .modelHeader {
      p {
        color: black;
      }
    }
    .modalBtns {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      gap: 20px;
    }
  }
}
