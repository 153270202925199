@import "./variables";
.login-form-design {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  margin: 20px;
  overflow: hidden;

  &__image {
    width: 500px;
    height: auto;
  }
  &__form {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
    width: 700px;
    .logo {
      width: 500px;
      height: 150px;
      img {
        width: 100%;
        height: 100%;
      }
    }

    .form-row {
      display: flex;
      margin-top: 20px;
      justify-content: space-evenly;
      width: 100%;
      a {
        color: $primary_color;
      }
      .remember_me {
        display: flex;
        p {
          margin-right: 10px;
        }
      }
      h3 {
        color: $light_grey_color;
        display: flex;
        position: relative;
        &:before {
          content: "";
          display: inline-block;
          margin-top: 10px;
          margin-right: 3px;
          width: 40px;
          height: 3px;
          background: $light_grey_color;
        }
        &:after {
          content: "";
          display: inline-block;
          margin-top: 10px;
          margin-left: 3px;
          width: 40px;
          height: 3px;
          background: $light_grey_color;
        }
      }
    }
    .logos_row {
      display: flex;
      justify-content: center;
      margin-top: 20px;
      .googleBtn {
        width: 64px;
        height: 64px;
      }
      .mybtn {
        width: 64px !important;
        height: 64px !important;
      }
      .medgrizz {
        width: 64px;
        height: 64px;
        margin-left: 20px;
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .login-form-design {
    &__form {
      .logo {
        width: 100%;
      }
    }
  }
}
