@import "./_variables.scss";
$pad: 0.925rem;

.admin-main-sidebar__nav {
  .nav_logo {
    width: 250px;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    img {
      width: 150px;
      height: 150px;
    }
  }
  .nav_logo_admin {
    margin-top: 20px;
    margin-left: 16px;
    img {
      width: 50px;
      height: 50px;
    }
  }
  .main-heading-of-sidebar {
    display: none;
  }
  .nav_logo {
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    transition: all 0.2s;
    img {
      width: 50px;
      height: 50px;
      margin-left: 30px;
      margin-top: 15px;
    }
  }
  .main-heading-of-sidebar {
    margin-left: 50px;
    font-size: 25px;
    width: fit-content;
    color: white;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
    padding: 10px 0px 10px 0px;
  }
  li {
    &:hover {
      background-color: $secondary_color;
      &:before {
        background-color: $secondary_color;
      }
      .icon {
        background-color: $secondary_color;
        fill: $color_white;
      }
      a {
        color: $color_white;
      }
    }
    &:before {
      position: absolute;
      left: -$pad;
      top: 0;
      width: $pad;
      height: 100%;
      content: "";
      background: $primary_color;
      z-index: 1;
      transition: background 400ms;
    }
  }
  ul {
    margin: 20px;
    padding: 0;
    list-style: none;
  }
  li {
    position: relative;
    clear: both;
    width: 100%;
    padding: 0;
    transition: background 400ms;
    a {
      display: flex;
      padding: {
        top: 2vh;
        bottom: 2vh;
      }
    }
  }
  a {
    align-items: center;
    width: 100%;
    color: $color_white;
    text-decoration: none;
    transition: color 400ms;

    span {
      color: $color_white;
      position: relative;
      display: block;
      z-index: 0;
      margin-left: 12px;
      text-indent: -20em;
      white-space: nowrap;
      transition: text-indent 400ms;
    }
    .custom-arrow {
      display: none;
      //transition: all 200ms ease-in-out;
    }
  }
  .icon {
    position: relative;
    max-width: 80px;
    max-height: 30px;
    z-index: 1;
    fill: $color_white;
    background: $primary_color;
    transition: 400ms;
  }
  .dropdown-container {
    display: none;

    //background-color: $secondary_color;
    // padding-left: 80px;

    &__nested-links {
      background: $ternary_color;
      padding-left: 80px;
      align-items: center;
      color: $color_white;
      &:hover {
        color: $color_white;
        background: $ternary_color;
      }
    }
  }
}
.admin-main-sidebar {
  position: sticky;
  bottom: 0;
  width: 80px;
  height: 100%;
  // z-index: 1;
  background: $primary_color;
  transition: width 400ms;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-between;

  &:hover {
    width: 280px;
    .main-heading-of-sidebar {
      display: flex;
      margin-top: 30px;
    }
    .nav_logo {
      width: 140px;
      height: 140px;
      margin-left: 30px;
      img {
        width: 140px;
        height: 140px;
        margin-left: 50px;
        margin-top: 15px;
      }
    }
    .nav_logo_admin {
      // width: 150px;
      // height: 150px;

      img {
        // width: 150px;
        // height: 150px;
        width: 50px;
        height: 50px;
      }
    }
    a {
      span {
        text-indent: 0;
      }
      .custom-arrow {
        display: flex;
        margin-top: -40px;
        // margin-right: 30px;
        align-self: end;
        color: $color_white;
        margin-bottom: 7px;
      }
    }
    .dropdown-btn {
      text-decoration: none;
      width: 100%;
      text-align: left;
      cursor: pointer;
      outline: none;
      display: flex;
      justify-content: space-between;
      padding-left: 20px;
      padding-right: 20px;
      transition: all 400ms;
    }

    .show {
      display: block;
    }
    &__nav {
      .nav_logo {
        width: 250px;
        height: 200px;
        img {
          width: 250px;
          height: 200px;
        }
      }

      li {
        @for $i from 1 through 4 {
          &:nth-of-type(#{$i}) {
            span {
              transition-delay: 100ms * $i;
            }
          }
        }
      }
      span {
        text-indent: 0;
      }
    }
    .user-section {
      display: flex;
      align-items: center;

      h6 {
        display: block;
        padding: 10px;
        color: white;
        font-size: medium;
        font-weight: 500;
        // margin-left: 10px;
      }

      svg {
        color: white;
        display: block;
      }
    }
  }
  .dropdown-btn {
    padding-left: 20px;
  }
  .user-section {
    padding-left: 20px;
    margin-bottom: 20px;
    position: relative;
    img {
      width: 50px;
      height: 50px;
      border-radius: 50%;
    }
    h6 {
      display: none;
    }
    svg {
      color: white;
      //display: none;
    }
    .myIcon {
      display: none;
    }
    .arrow-section {
      display: none;
      position: absolute;
      background-color: $secondary_color;
      padding: 12px;
      right: 10px;
      top: -80px;
      color: white;
    }
    .show {
      display: block;
      ul {
        list-style: none;
        li {
          padding: 10px;
          &:hover {
            background-color: gray;
          }
        }
      }
    }
  }
}
.staticWidth {
  width: 280px;
  a {
    span {
      text-indent: 0;
    }
    .custom-arrow {
      display: flex;
      margin-top: -40px;
      // margin-right: 30px;
      align-self: end;
      color: $color_white;
      margin-bottom: 7px;
    }
  }
  &__nav {
    .nav_logo {
      width: 250px;
      height: 200px;
      img {
        width: 250px;
        height: 200px;
      }
    }
    li {
      @for $i from 1 through 4 {
        &:nth-of-type(#{$i}) {
          span {
            transition-delay: 100ms * $i;
          }
        }
      }
    }
    span {
      text-indent: 0;
    }
  }
  .user-section {
    display: flex;
    align-items: center;
    justify-content: space-between;

    h6 {
      // display: block;
      display: initial;
      padding: 10px;
      color: white;
      font-size: medium;
      font-weight: 500;
      //margin-left: 10px;
    }
    svg {
      display: block;
      margin-right: 40px;
    }
  }
}
@media (max-height: 600px) {
  .admin-main-sidebar:hover {
    .nav_logo {
      width: 50px;
      height: 50px;
      margin-left: 30px;
      img {
        width: 50px;
        height: 50px;
        margin-left: 30px;
        margin-top: 15px;
      }
    }
  }
}
