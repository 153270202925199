* {
  font-family: "Open Sans", sans-serif;
  /* font-size: 20px; */
}
body {
  background-color: #fff;
  width: 100%;
  overflow-x: hidden;
}
html,
body {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;
}
