@import "./variables.scss";
@import "./minxinsStyling.scss";
.dashboard-wrapper {
  display: flex;
  position: relative;
  width: 100vw;
  height: 100vh;

  .admin-nested {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: scroll;
    &-heading {
      margin-top: 10px;
      margin-left: 20px;
    }

    .sorting_wrapper {
      h1 {
        width: auto;
        display: block;
        align-self: center;
        justify-content: flex-start;
      }
      .sorted_actions_wrapper {
        display: flex;
        // justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
        // flex-wrap: wrap;
        //display: flex;
        h3 {
          min-width: 80px;
          margin-top: 20px;
        }
        form {
          width: 95%;
          .sorted_actions {
            width: 90%;
            display: flex;
            justify-content: space-evenly;
            flex-wrap: wrap;
            // form {
            //   width: 90%;
            //   display: flex;
            //   justify-content: space-evenly;
            //   flex-wrap: wrap;
            // }
            // button {
            //   display: inline-flex;
            // }
          }
        }
      }
      .sorted_wrapper_simple {
        display: flex;
      }

      // @include sortingCardDesign(100%, 120px);
    }

    .form-wrapper {
      display: flex;
      justify-content: center;

      overflow-y: scroll;
      // margin: 20px;
      height: 100%;

      // position: absolute;
      // top: 10px;
      .dashboard-form {
        margin-top: 20px;
        padding: 20px;
        margin: 40px;
        height: 100%;
        @include cardDesign(600px, 100%);
        .edit-bulk {
          width: 100%;
          height: 600px;
          overflow: scroll;
          // border: 1px solid $primary_color;
          .item {
            display: inline-flex;
            margin: 16px;
          }
        }
        .edit-plans {
          width: 100%;
          height: 500px;
          overflow-y: scroll;
          border: 1px solid $primary_color;
          .item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 16px;
            margin: 16px;
            border: 1px solid $primary_color;
          }
        }
        .plans-card {
          border: 5px solid $primary_color;
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-start;
          padding: 5px;
          margin: 3px;
          .plans_row_1 {
            width: 100%;
            display: flex;
            justify-content: space-around;
            input {
              margin: 3px;
            }
          }
        }
      }
      .image-previewer {
        display: flex;
        align-items: center;
        img {
          width: 100px;
          height: 100px;
        }
      }
      .selection_area {
        display: flex;
        justify-content: space-between;
      }
      .adding_plans_wrapper {
        display: flex;
        flex-direction: column;
        .plan_added {
          display: flex;
          justify-content: space-between;
          margin: 10px;

          display: flex;

          justify-content: space-between;
          position: relative;
          margin: 10px;
          background: rgb(212, 219, 234);
          padding: 10px;
          .delete-icon {
            position: absolute;
            right: 30px;
            margin-top: 0px;
            color: crimson;
            font-size: 20px;
            top: -10px;
            transition: all 0.2s;

            &:hover {
              font-size: 24px;
            }
          }
        }
      }
      .w-90 {
        width: 90%;
      }

      .dashboard-table {
        margin: 20px;
        padding: 20px;

        @include cardDesign(100%, 100%);

        &__header {
          display: flex;
          justify-content: space-between;
          h1 {
            width: auto;
          }
          .action_btns {
            display: flex;
            justify-content: flex-end;
            flex-wrap: wrap;
            align-self: flex-end;
          }
        }
        .session-customization-wrapper {
          width: 100%;
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;

          &__inputboxes {
            width: 100%;
            display: flex;
            flex-direction: row;
            // flex-wrap: wrap;
            // margin: 10px;
            .left {
              width: 100%;
            }
            .right {
              width: 100%;
              margin-left: 20px;
            }
          }
          &__checkBoxes {
            display: flex;
            margin: 20px 0px;

            .right {
              margin-left: 20px;
              display: flex;
              flex-wrap: wrap;
              input {
                margin-left: 20px;
                padding-bottom: 20px;
              }
            }
          }
        }
        .content-right {
          display: flex;
          justify-content: flex-end;
        }
      }
      .dashboard-Data {
        display: flex;
        flex-direction: column;
        padding: 20px;
        margin-top: 30px;
        .help-center-detail-item {
          margin-top: 10px;
          display: flex;
          // justify-content: center;
          // align-items: center;
          h5 {
            padding-left: 30px;
            align-self: center;
          }
        }
        .report_row {
          display: flex;
          justify-content: space-between;
        }
        .questions_list {
          margin-left: 30px;
          margin-top: 12px;
          margin-bottom: 12px;
        }
        .report_row_flex {
          display: flex;
          width: fit-content;
          justify-content: space-between;
          p {
            margin-left: 10px;
            align-self: flex-end;
          }
        }

        @include cardDesign(1000px, 100%);
      }
    }
    .dashboard-cards-wrapper {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;

      margin-right: 40px;
      margin-bottom: 40px;
      //margin: 20px;
      &__header {
        display: flex;
        justify-content: space-between;
        .heading {
          width: auto;
          margin-top: 20px;
          margin-left: 20px;
          margin-bottom: 0;
          padding-bottom: 5px;
        }
        .action_btns {
          display: flex;
          justify-content: flex-end;
          flex-wrap: wrap;
          align-self: flex-end;
        }
      }
      .cardDesigns {
        margin: 20px;
        padding: 20px;

        // @include cardDesign(100%, 100%);
        display: flex;
        flex-direction: column;
        width: 100%;
        height: fit-content;

        padding-bottom: 20px;
        border-radius: 10px;
        border: 2px solid $primary_color;
        .customize-search {
          display: flex;
          justify-content: space-between;
          margin-top: 10px;
        }
        .session-customization-wrapper {
          width: 100%;
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;

          &__inputboxes {
            width: 100%;
            display: flex;
            flex-direction: row;
            // flex-wrap: wrap;
            // margin: 10px;
            .left {
              width: 100%;
            }
            .right {
              width: 100%;
              margin-left: 20px;
            }
          }
          &__checkBoxes {
            display: flex;
            margin: 20px 0px;

            .right {
              margin-left: 20px;
              display: flex;
              flex-wrap: wrap;
              input {
                margin-left: 20px;
                padding-bottom: 20px;
              }
            }
          }
          &__subject {
            display: flex;
            flex-wrap: wrap;
            margin: 20px 0px;

            label {
              margin-right: 20px;
              width: 200px;
            }
          }
        }
        .content-right {
          display: flex;
          justify-content: flex-end;
        }
      }
      .pq-btn-wrapper {
        display: flex;
        justify-content: flex-end;
        width: 200px;
      }
    }
    .start__question {
      width: 100%;
      height: 100%;
      display: flex;
      //flex-wrap: wrap;
      overflow: hidden;
      border: 2px solid $primary_color;

      &_left {
        width: 100%;
        height: 100%;
        border-right: 2px solid $primary_color;
        overflow: scroll;
        &-header {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          &-left {
            display: flex;
            margin-left: 10px;
            align-items: center;
          }
          &-right {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            .btns {
              display: flex;
              justify-content: space-between;
              .single-btn {
                border: 2px solid $primary_color;
                padding: 10px;
                margin: 5px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                &-heading {
                  margin-top: 5px;
                }
              }
              .back {
                background-color: yellow;
              }
            }
          }
        }
        &-questionWrapper {
          display: flex;
          // height: 100%;
          margin-top: 10px;
          padding: 20px;
          text-align: justify;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          margin-right: 20px;
          overflow-y: scroll;
          position: relative;

          &::-webkit-scrollbar {
            -webkit-appearance: none;
            width: 7px;
          }

          &::-webkit-scrollbar-thumb {
            border-radius: 4px;
            background-color: rgba(0, 0, 0, 0.5);
            box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
          }
          .questionImagewrapper {
            width: 100%;
            height: 30rem;

            .imageOfQuestion {
              //object-fit: cover;
              height: 100%;
              width: 100%;
            }
          }

          .question-text-hide {
            display: none;
          }
          .radio-buttons-container {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 24px;
            margin-top: 20px;
          }
          .radio-buttons-container-hide {
            display: none;
          }
          .start__question_left_answer {
            //display: none;
            //position: absolute;
          }
          .start__question_left_answer-show {
            display: flex;
          }
          .question-pagination-wrapper {
            margin-top: 20px;
            width: 100%;
            display: flex;
            justify-content: space-between;

            .pagination-item {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              .redFlagFilled {
                color: red;
              }
            }
          }
        }
        .start__question_left-reportWrapper {
          display: flex;
          flex-direction: column;
          border-top: 2px solid $primary_color;
          //  display: none;
          .report-topic {
            padding: 20px;
            .selectReportTopic {
              width: fit-content;
            }
          }
        }
        .show {
          display: flex;
        }
      }
      &_right {
        display: flex;
        flex-direction: column;
        width: 30%;
        height: 100%;
        &-header {
          border-bottom: 2px solid $primary_color;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 20px 0px;
          .scores_section {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 12px;
            .left {
              display: flex;
              flex-direction: column;

              border-right: 2px solid $primary_color;
              padding-right: 12px;
              .bottom {
                display: flex;
                align-items: center;
                justify-content: center;
              }
            }
            .right {
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
            }
          }
        }
        &-body {
          display: flex;
          flex-direction: column;
          width: 100%;
          padding: 12px;
          height: 60%;

          .question_nos_wrapper {
            display: flex;
            flex-wrap: wrap;
            overflow-y: scroll;

            &::-webkit-scrollbar {
              -webkit-appearance: none;
              width: 7px;
            }

            &::-webkit-scrollbar-thumb {
              border-radius: 4px;
              background-color: rgba(0, 0, 0, 0.5);
              box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
            }
            .question_no {
              border: 2px solid $primary_color;
              padding: 3px;
              margin: 5px;
              text-align: center;
              flex: 1 0 calc(15% - 10px);

              justify-content: center;

              &:hover {
                background-color: aqua;
              }
            }
            .question_no_answered {
              background-color: rgb(66, 146, 238);
            }
            .question_no_flagged {
              border-bottom: 5px solid red;
            }
            .question_no_not_answered {
              background-color: rgb(209, 215, 222);
            }
          }
        }
        &-footer {
          display: flex;
          // height: 100%;
          border-top: 2px solid $primary_color;
          //border-left: 2px solid $primary_color;
          .footer-items {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            width: 100%;

            margin: 20px;
            box-sizing: border-box;
            //border-right: 2px solid $primary_color;
            .footer-item {
              display: flex;
              flex-direction: column;
              padding: 16px;
              width: 92px;
              margin: 10px;
              justify-content: center;
              align-items: center;
              border: 2px solid $primary_color;
            }
          }
        }
      }
    }
    .mock_test {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .mock_test-heading {
        margin: 20px 30px;
      }
      .mock_test-sections {
        display: flex;
        //width: 100%;
        border: 2px solid $primary_color;
        margin: 20px 30px 20px 30px;
        &-left {
          //margin: ;
          display: flex;
          flex-direction: column;
          width: 100%;
          .header {
            border-bottom: 2px solid $primary_color;
            padding: 20px;
            text-align: center;
          }
          .body {
            padding: 20px;
            //text-align: center;
          }
          border-right: 2px solid $primary_color;
        }
        &-right {
          //margin: ;
          display: flex;
          flex-direction: column;
          width: 100%;
          .header {
            border-bottom: 2px solid $primary_color;
            padding: 20px;
            text-align: center;
          }
          .body {
            padding: 20px;
          }
        }
      }
    }

    .performance-and-analytics {
      display: flex;
      flex-direction: column;
      gap: 30px;

      .fetching {
        display: flex;
        justify-content: flex-end;
        margin: 20px;
      }
      .graphs {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        flex-wrap: wrap;
        margin: 20px;
        // margin-bottom: 100px;
        .graph-left {
          display: flex;
          flex-direction: column;
          gap: 20px;
          justify-content: center;
          align-items: center;
          .graphData {
            width: 250px;
            height: 250px;
          }
          .graphDatalarge {
            width: 500px;
            height: 500px;
            margin-left: 20px;
            //transform: rotate(-90deg);
          }
        }
        .graph-right {
          display: flex;
          flex-direction: column;
          gap: 20px;
          justify-content: center;
          align-items: center;
          .graphDatalarge {
            width: 500px;
            height: 500px;
            margin-left: 20px;
          }
        }
      }
    }
    .review-center {
      display: flex;
      flex-direction: column;
      overflow-y: scroll;
      .main_review_heading {
        margin: 16px 30px;
      }
      a {
        text-decoration: none;
      }
      .fetching {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        gap: 30px;
        form {
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          margin-right: 20px;
          gap: 16px;
        }
      }
      .single-review {
        display: flex;
        justify-content: space-between;
        padding: 30px;
        margin: 12px 20px;
        border: 3px solid $primary_color;
        // background-color: rgb(178, 209, 220);
        color: white;
        background: linear-gradient(
          90deg,
          rgba(18, 17, 36, 1) 0%,
          rgba(79, 68, 74, 1) 48%,
          rgba(12, 33, 37, 1) 86%
        );
      }
    }
    .review-center-detail {
      display: flex;
      flex-direction: column;
      margin: 30px;

      &-top {
        display: flex;
        flex-direction: column;
        gap: 20px;
      }
      &-questions-list {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px;
        .questionsList {
          display: flex;
          flex-wrap: wrap;
          overflow-y: scroll;
          height: 300px;
          overflow-y: scroll;

          &::-webkit-scrollbar {
            -webkit-appearance: none;
            width: 7px;
          }

          &::-webkit-scrollbar-thumb {
            border-radius: 4px;
            background-color: rgba(0, 0, 0, 0.5);
            box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
          }
          .question_no {
            width: fit-content;
            height: fit-content;
            border: 2px solid $primary_color;
            padding: 3px;
            margin: 5px;
            text-align: center;
            flex: 1 0 calc(5% - 10px);
            justify-content: center;
          }
          .question_no_answered {
            background-color: rgb(66, 146, 238);
          }
          .question_no_flagged {
            border-bottom: 5px solid red;
          }
          .question_no_not_answered {
            background-color: rgb(209, 215, 222);
          }
        }
      }
    }
    .help-and-support {
      display: flex;
      flex-direction: column;
      margin: 30px;
      .help-image-previewer {
        display: flex;
        align-items: center;
        img {
          width: 150px;
          height: 150px;
        }
      }
      .help-btn {
        width: 300px;
      }
    }
    .help-and-support-detail {
      display: flex;
      flex-direction: column;
      margin: 30px;
      height: 100%;

      &-message {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        &-content {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 400px;
          gap: 20px;

          .back-btn-wrapper {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 10px;

            padding: 10px;
          }
        }
      }
    }
  }
}
.checkout-wrapper {
  display: flex;
  width: 100%;
  height: 90vh;
  overflow: hidden;

  padding: 40px;
  .checkout-wrapper-left {
    flex: 3;

    border-right: 2px solid #b4a9a9e5;
    margin-right: 20px;
    .userDetails {
      display: flex;
      flex-direction: column;

      .account-heading {
        // display: block;
        margin-bottom: 12px;
      }
      .user-wrapper {
        display: flex;
        .user_image {
          width: 80px;
          height: 80px;
          border-radius: 50%;
        }
        .user_data {
          display: flex;
          flex-direction: column;
          //align-items: center;
          justify-content: center;
          margin-left: 20px;
        }
      }
    }
    .line {
      width: 70%;
      height: 2px;
      background-color: #b4a9a9e5;
      margin-left: auto;
      margin-right: auto;
      margin-top: 20px;
      margin-bottom: 20px;
    }
    .stripe-card {
      margin: 1rem 0;
      display: flex;
      flex-direction: column;
      width: 100%;
      .single-card-element {
        display: flex;
        flex-direction: column;

        margin-right: 20px;
        margin-top: 10px;
        gap: 2px;
        label {
          margin-bottom: 0.25rem;
          font-size: 0.93rem;
          transition: transform 0.5s cubic-bezier(0.19, 1, 0.22, 1),
            opacity 0.5s cubic-bezier(0.19, 1, 0.22, 1);
        }
      }
      .card-element {
        border: 1px solid grey;
        border-radius: 3px;
        padding: 10px;
      }
    }
    .row-of-stripe-elements {
      display: flex;
      .single-card-element {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 3px;
        margin-right: 20px;
        margin-top: 10px;
        gap: 2px;
        .card-element {
          border: 1px solid grey;
          border-radius: 3px;
          padding: 10px;
        }
      }
    }
  }
}

.checkout-wrapper-right {
  flex: 1;
  .planDesign {
    display: flex;
    flex-direction: column;

    .total-wrapper {
      display: flex;
      justify-content: space-between;
    }
  }
}

.StripeElement {
  //width: 80% !important;
}
.card-item-btn {
  margin-right: 20px;
}
.form-header {
  width: 100%;
  height: 50px;
  padding: 10px;
  color: $color_white;
  background-color: $primary_color;
}
#customers {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: initial;
  height: 100%;
  overflow: hidden;
  table-layout: fixed;

  & thead th,
  & tbody td {
    border: 1px solid #ddd;
    padding: 8px;
    overflow: hidden;
    white-space: wrap;
    width: fit-content;
    text-align: justify;
  }

  & tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  & td.actions {
    // width: 100%;
    // height: 100%;

    &_buttons {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
    }
  }
  tbody {
    tr:hover {
      background-color: $lightest_color;
    }
  }
  thead {
    th {
      padding-top: 12px;
      padding-bottom: 12px;
      text-align: left;
      background-color: $primary_color;
      color: white;
    }
  }
}
.form-wrapper {
  display: flex;
  justify-content: center;

  overflow-y: scroll;
  // margin: 20px;
  height: 100%;

  // position: absolute;
  // top: 10px;
  .dashboard-form {
    margin-top: 20px;
    padding: 20px;
    margin: 40px;
    height: 100%;
    @include cardDesign(600px, 100%);
    .edit-bulk {
      width: 100%;
      height: 600px;
      overflow: scroll;
      // border: 1px solid $primary_color;
      .item {
        display: inline-flex;
        margin: 16px;
      }
    }
    .edit-plans {
      width: 100%;
      height: 500px;
      overflow-y: scroll;
      border: 1px solid $primary_color;
      .item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 16px;
        margin: 16px;
        border: 1px solid $primary_color;
      }
    }
    .plans-card {
      border: 5px solid $primary_color;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      padding: 5px;
      margin: 3px;
      .plans_row_1 {
        width: 100%;
        display: flex;
        justify-content: space-around;
        input {
          margin: 3px;
        }
      }
    }
    .adding_plans_wrapper {
      display: flex;
      flex-direction: column;
      .plan_added {
        display: flex;
        justify-content: space-between;
        margin: 10px;

        display: flex;

        justify-content: space-between;
        position: relative;
        margin: 10px;
        background: rgb(212, 219, 234);
        padding: 10px;
        .delete-icon {
          position: absolute;
          right: 30px;
          margin-top: 0px;
          color: crimson;
          font-size: 20px;
          top: -10px;
          transition: all 0.2s;

          &:hover {
            font-size: 24px;
          }
        }
      }
    }
  }
  .image-previewer {
    display: flex;
    align-items: center;
    img {
      width: 100px;
      height: 100px;
      border-radius: 100%;
      margin: 20px;
    }
  }
  .selection_area {
    display: flex;
    justify-content: space-between;
  }
  .w-90 {
    width: 90%;
  }
  .dashboard-table {
    margin: 20px;
    padding: 20px;

    @include cardDesign(100%, 100%);

    &__header {
      display: flex;
      justify-content: space-between;
      h1 {
        width: auto;
      }
      .action_btns {
        display: flex;
        justify-content: flex-end;
        flex-wrap: wrap;
        align-self: flex-end;
      }
    }
    .content-right {
      display: flex;
      justify-content: flex-end;
    }
  }
  .dashboard-Data {
    display: flex;
    flex-direction: column;
    padding: 20px;
    margin-top: 30px;
    .help-center-detail-item {
      margin-top: 10px;
      display: flex;
      // justify-content: center;
      // align-items: center;
      h5 {
        padding-left: 30px;
        align-self: center;
      }
    }
    .report_row {
      display: flex;
      justify-content: space-between;
    }
    .questions_list {
      margin-left: 30px;
      margin-top: 12px;
      margin-bottom: 12px;
    }
    .report_row_flex {
      display: flex;
      width: fit-content;
      justify-content: space-between;
      p {
        margin-left: 10px;
        align-self: flex-end;
      }
    }

    @include cardDesign(1000px, 100%);
  }
}
@media (max-width: 480px) {
  .checkout-wrapper {
    padding: 20px;
    .checkout-wrapper-left {
      border: none;
      .userDetails {
        margin-bottom: 160px;
        .user-wrapper {
          .user_image {
            width: 50px;
            height: 50px;
          }
          .user_data {
            h3 {
              font-size: 12px;
            }
          }
        }
      }
      .line {
        width: 90%;
        margin-left: 0px;
      }
    }
    .checkout-wrapper-right {
      padding: 10px;
      width: 80%;
      /* margin-left: 10px; */
      border-top: 2px solid #b4a9a9e5;
      position: absolute;
      top: 30%;
    }
  }
}
