.timer-wrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  &-time {
    font-size: 40px;
    border: 2px solid black;
    padding: 2px 10px;
  }
  .show {
    display: inline-flex;
  }
  .hide {
    display: none;
  }
}
