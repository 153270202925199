@import "./variables";

.selectWrapper {
  display: flex;
  flex-direction: column;
  // gap: 20px;
  .mySelectLabel {
    width: fit-content;

    font-weight: 300;
    position: relative;
    top: 0.2rem;
    margin: 10px 0 0 7px;
    padding: 3px 5px;
    background: $secondary_color;
    color: $input_label_color;

    // padding-top: 40px;
  }
}
.selectWrapper-width-fit-content {
  width: fit-content;
}

select {
  // A reset of styles, including removing the default dropdown arrow
  //   appearance: none;
  background-color: transparent;
  border: none;
  padding: 0 1em 0 0;
  margin: 0;
  // width: 100px;
  font-family: inherit;
  font-size: inherit;
  cursor: inherit;
  line-height: inherit;
  outline: none;
  // Stack above custom arrow
  z-index: 1;

  // Remove dropdown arrow in IE10 & IE11
  // @link https://www.filamentgroup.com/lab/select-css.html
  &::-ms-expand {
    display: none;
  }

  // Remove focus outline, will add on alternate element
  //   outline: none;
}
.width-fit-content {
  width: fit-content;
}
.width-full-90 {
  width: 90%;
}

.select {
  display: grid;
  grid-template-areas: "select";
  align-items: center;
  position: relative;

  select,
  &::after {
    grid-area: select;
  }

  //min-width: 15ch;
  max-width: 100%;

  border: 1px solid $input_border_color;
  border-radius: 0.25em;
  padding: 0.25em 0.5em;

  font-size: 1.25rem;
  cursor: pointer;
  line-height: 1.1;

  // Optional styles
  // remove for transparency
  //   background-color: #fff;
  //   background-image: linear-gradient(to top, #b02222, #1453e4 33%);

  // Custom arrow
  &:not(.select--multiple)::after {
    content: "";
    justify-self: end;
    width: 0.8em;
    height: 0.5em;
    background-color: var(--select-arrow);
    clip-path: polygon(100% 0%, 0 0%, 50% 100%);
  }
}
.select-error {
  border: 2px solid rgb(242, 50, 50);
}

// Interim solution until :focus-within has better support
select:focus + .focus {
  position: absolute;
  top: -1px;
  left: -1px;
  right: -1px;
  bottom: -1px;
  border: 2px solid $input_border_color_on_active;
  border-radius: inherit;
}
