.imageWrapper {
  position: relative;
}
.terms-image {
  // width: 100%;
  // filter: blur(8px);
  // -webkit-filter: blur(8px);
  // img {
  //   width: 100%;
  //   height: 400px;
  // }
  padding: 30px;
  /* The image used */
  // background-image: url("https://plus.unsplash.com/premium_photo-1666533184810-6a0476aa1807?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=687&q=80");
  background-image: url(../images/Faq_img.jpeg);
  /* Add the blur effect */
  filter: blur(8px);
  -webkit-filter: blur(8px);

  /* Full height */
  height: 400px;

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.about_us_image {
  background-image: url(../images/About_Us_img.jpeg);
}
.terms_of_service_image {
  background-image: url(../images/terms_of_services_img.jpeg);
}
.privacy_image {
  background-image: url(../images/privacy_plicy_img.jpeg);
}
.main-heading {
  font-size: 20px;

  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/opacity/see-through */
  color: white;
  font-weight: bold;
  border: 3px solid #f1f1f1;
  border-radius: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  //z-index: 2;
  width: 80%;
  padding: 20px;
  text-align: center;
}
.generic-list {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 20px;
  .list-item-card {
    margin: 10px 50px 10px 30px;
    //width: 90%;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034),
      0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06),
      0 22.3px 17.9px rgba(0, 0, 0, 0.072), 0 41.8px 33.4px rgba(0, 0, 0, 0.086),
      0 100px 80px rgba(0, 0, 0, 0.12);
    //box-shadow: ;
    .card-heading {
      padding: 16px;
    }
    .card-text {
      padding: 16px;
      text-align: justify;
      margin-bottom: 16px;
      line-height: 1.5rem;
    }
    .card-list {
      //padding: 16px;

      margin-left: 32px;
    }
    .profileWrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      margin: 40px 0px 40px 0px;
      .leftWrapper {
        display: flex;
        flex-direction: column;
        width: fit-content;
        padding: 20px;
        align-items: center;
        justify-content: center;
        width: 300px;

        img {
          width: 200px;
          height: 200px;
          border-radius: 50%;
        }
        button {
          margin-top: 20px;
          margin-left: 10px;
        }
      }
      .rightWrapper {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        flex: 2;
        .profileRow {
          width: 80%;
          display: flex;
          flex-wrap: wrap;

          // justify-content: space-between;
          margin: 10px;
          .heading-text-main {
            margin-left: 12px;
          }
          .plans {
            display: flex;
            flex-direction: column;
            margin-left: 12px;
          }
          // h3 {
          //   margin-left: 20px;
          // }
        }
      }
    }
  }
}

.bg-image {
  padding: 30%;
  /* The image used */
  // background-image: url("https://plus.unsplash.com/premium_photo-1666533184810-6a0476aa1807?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=687&q=80");
  background-image: url(../images/bg1.jpeg);
  /* Add the blur effect */
  filter: blur(8px);
  -webkit-filter: blur(8px);

  /* Full height */
  height: 100%;

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.about_us {
  background-image: url(../images/bg1.jpeg);
}

/* Position text in the middle of the page/image */
.bg-text {
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/opacity/see-through */
  color: white;
  font-weight: bold;
  border: 3px solid #f1f1f1;
  border-radius: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  width: 80%;
  padding: 20px;
  text-align: center;
}
@media screen and (max-width: 920px) {
  .bg-image {
    height: 100vh;
    overflow: hidden;
  }
  .bg-text {
    height: 80%;
    margin-top: 50px;
    overflow: scroll;
  }
}

@media screen and (max-width: 480px) {
  .bg-image {
    height: 100vh;

    overflow: hidden;
  }
  .bg-text {
    height: 85%;
    margin-top: 50px;
    overflow: scroll;
  }
}
