.pricing-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 220px;
  height: 240px;
  padding: 20px 1px;
  margin: 10px 0;
  text-align: center;
  position: relative;
  cursor: pointer;
  box-shadow: 0 10px 15px -3px rgba(33, 150, 243, 0.4),
    0 4px 6px -4px rgba(33, 150, 243, 0.4);
  border-radius: 30px;
  // background-color: #6b6ecc;
  background-color: #0c0059;
  // background: linear-gradient(45deg, #04051dea 0%, #2b566e 100%);
}
.pricing-card-extended {
  justify-content: flex-start;
  width: 250px;
  height: 400px;
}
.grey-background {
  background-color: #424242;
}

.content {
  // color: white;
  padding: 20px;
}
.content-extended {
  padding: 10px;
}

.content .price {
  color: white;
  font-weight: 800;
  font-size: 50px;
  text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.42);
}

.content .description {
  // color: rgba(255, 255, 255, 0.6);
  color: white;
  margin-top: 10px;
  font-size: 14px;
}

.content .title {
  font-weight: 800;
  text-transform: uppercase;
  //color: rgba(255, 255, 255, 0.64);
  color: white;
  margin-top: 10px;
  font-size: 18px;
  letter-spacing: 1px;
}
.content .examTitle {
  font-weight: 900;
  color: white;
  letter-spacing: 1.5px;
  margin-top: 5px;
  font-size: 18px;
}

button {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  border: none;
  outline: none;
  color: rgb(255 255 255);
  text-transform: uppercase;
  font-weight: 700;
  font-size: 0.75rem;
  padding: 0.75rem 1.5rem;
  background-color: rgb(33 150 243);
  border-radius: 0.5rem;
  width: 90%;
  text-shadow: 0px 4px 18px #2c3442;
}
.loginToShowBtn {
  color: white;
}
