// ======================= SCSS Variables ============================
// $primary_color: #11009e;
$primary_color: #192841;
// $secondary_color: #4942e4;
$secondary_color: #4f709c;
$ternary_color: #8696fe;
//$lightest_color: #8696fe;
$lightest_color: #d8c4b6;
$theme_background_color: #f1f6f9;
$sidebar_text_color: white;
$sidebarItem_transform: 10px;
$input_border_color: $primary_color;
$input_border_radius: 16px;
$input_label_color: white;
$input_label_focus_or_active_bg_color: #e8e8e8;
$input_border_color_on_active: $secondary_color;

$button_color: white;

$color_white: #ffffff;

$light_grey_color: #071d2a84;
