.search-input {
  border: 2px solid transparent;
  width: 15em;
  height: 2.5em;
  padding-left: 0.8em;
  outline: none;
  overflow: hidden;
  background-color: #f3f3f3;
  border-radius: 10px;
  transition: all 0.5s;

  &:hover,
  &:focus {
    border: 2px solid #4a9dec;
    box-shadow: 0px 0px 0px 7px rgb(74, 157, 236, 20%);
    background-color: white;
  }
}

// .search-input:hover,
// .search-input:focus {
//   border: 2px solid #4a9dec;
//   box-shadow: 0px 0px 0px 7px rgb(74, 157, 236, 20%);
//   background-color: white;
// }
