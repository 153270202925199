@import "./variables";

.coolinput {
  display: flex;
  flex-direction: column;

  position: static;
  width: 100%;
  // max-width: 400px;
}
.fit {
  position: relative;
  width: fit-content;
}

.coolinput label.text {
  font-size: 1rem;
  font-weight: 700;
  position: relative;
  top: 0.5rem;
  margin: 0 0 0 7px;
  padding: 3px 5px;
  background: $secondary_color;
  color: $input_label_color;
  width: fit-content;
}

.coolinput .input {
  padding: 14px 10px;
  font-size: 0.75rem;
  border: 2px $secondary_color solid;
  border-radius: 5px;
  background: transparent;
}
.coolinput .full {
  width: 100%;
  max-width: 100%;
}
.coolinput .input:focus {
  outline: none;
}
