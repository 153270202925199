@import "./variables";

.colors {
  color: $primary_color;
}
.primary_heading {
  box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset,
    rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}

.m-20 {
  margin: 20px;
}
.mt-20 {
  margin-top: 20px;
}
.w-90 {
  width: 90%;
}
.w-100 {
  width: 100%;
}
