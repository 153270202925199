@import "./variables";
.search {
  --input-line: #cccccc;
  --input-text-color: #808080;
  --input-text-hover-color: transparent;
  --input-border-color: #808080;
  --input-border-hover-color: #999999;
  --input-bg-color: #333333;
  --search-max-width: 250px;
  --search-min-width: 150px;
  --border-radius: 5px;
  --transition-cubic-bezier: 150ms cubic-bezier(0.4, 0, 0.2, 1);
  margin-top: 32px;
}

.search-box {
  max-width: var(--search-max-width);
  min-width: var(--search-min-width);
  height: 40px;
  border: 1px solid var(--input-border-color);
  border-radius: var(--border-radius);
  padding: 5px 15px;
  background: transparent;
  //   box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
  transition: var(--transition-cubic-bezier);
}

.search-box:hover {
  border-color: var(--input-border-hover-color);
}

/*Section input*/
.search-field {
  position: relative;
  width: 100%;
  height: 100%;
  left: -5px;
  border: 0;
}

.searchInput {
  width: calc(100% - 29px);
  height: 100%;
  border: 0;
  border-color: transparent;
  font-size: 1rem;
  padding-right: 0px;

  background: white;
  border-right: 2px solid var(--input-border-color);
  outline: none;
}

// .searchInput::-webkit-input-placeholder {
//   color: var(--input-text-color);
// }

// .searchInput::-moz-input-placeholder {
//   color: var(--input-text-color);
// }

// .searchInput::-ms-input-placeholder {
//   color: var(--input-text-color);
// }

// .searchInput:focus::-webkit-input-placeholder {
//   color: var(--input-text-hover-color);
// }

// .searchInput:focus::-moz-input-placeholder {
//   color: var(--input-text-hover-color);
// }

// .searchInput:focus::-ms-input-placeholder {
//   color: var(--input-text-hover-color);
// }

/*Search button*/
.search-box-icon {
  width: 52px;
  height: 40px;
  position: absolute;
  top: -6px;
  right: -21px;
  color: white;
  background: $primary_color;
  border-bottom-right-radius: var(--border-radius);
  border-top-right-radius: var(--border-radius);
  transition: var(--transition-cubic-bezier);
}

.search-box-icon:hover {
  background: $secondary_color;
}

.btn-icon-content {
  width: 52px;
  height: 35px;
  top: -6px;
  right: -21px;
  background: transparent;
  border: none;
  cursor: pointer;
  border-bottom-right-radius: var(--border-radius);
  border-top-right-radius: var(--border-radius);
  transition: var(--transition-cubic-bezier);
  opacity: 0.4;
}

.btn-icon-content:hover {
  opacity: 0.8;
}

.search-icon {
  width: 21px;
  height: 21px;
  position: absolute;
  top: 10px;
  right: 15px;
}
