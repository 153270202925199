@import "./variables";

.TopNavigation {
  height: 70px;
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  padding: 0.5rem 0rem;
  background-color: #fff;
  // color: white;
  background-color: #d9d9d9;
  box-shadow: 0 2px 2px 2px rgba(9, 9, 9, 0.23);

  .hamburger {
    // removes default border on button element
    border: 0;
    height: 40px;
    width: 40px;
    padding: 0.5rem;
    border-radius: 50%;
    background-color: #283b8b;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
    // positions the icon to the right and center aligns it vertically
    position: absolute;
    top: 50%;
    right: 25px;
    transform: translateY(-50%);
    display: none;

    &:hover {
      background-color: #2642af;
    }
  }
}

.brand-name {
  //font-family: "Lobster-Regular";
  text-decoration: none;
  color: white;
  font-size: 1.3rem;
  // margin-left: 0.2rem;
  img {
    margin-top: 10px;
    // margin-bottom: 10px;
    width: 250px;
    height: 60px;
    border-radius: 5px;
  }
}
.navigation-menu {
  margin-left: auto;
}

.navigation-menu ul {
  width: 100%;

  background: transparent;

  list-style: none;
  display: flex;
  flex-wrap: wrap;

  align-items: center;
  position: relative;

  a {
    text-decoration: none;

    li {
      display: flex;

      padding: 1rem 1rem;
      text-transform: uppercase;
      cursor: pointer;
      color: #000000;
      // min-width: 80px;
      margin: auto;
      position: relative;
      transition: all 0.2s ease-in-out;
      z-index: 2;
      font-size: 16px;
      font-weight: 600;
      &:hover {
        background-size: 100% 100%;
        color: $primary-color;
        font-size: 20px;
        font-weight: 800;
        animation: spring ease-out;
      }
      &:active {
        transform: translateY(4px);
      }

      .flag {
        width: 20px;
        height: 10px;
        border-radius: 0px;
        &:hover {
          background-color: #2642af;
        }
      }
    }
  }

  .profileShown {
    margin-right: 16px;
    li {
      img {
        width: 50px;
        height: 50px;
        border-radius: 100%;
      }
      .profile_wrapper {
        display: none;
      }
      .profile_wrapper-shown {
        // width: 300px;
        display: flex;
        flex-direction: column;
        background-color: #d9d9d9;
        position: absolute;
        // height: 100%;
        z-index: 100;
        top: 70px;
        width: 250px;
        right: 16px;
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .TopNavigation {
    .hamburger {
      display: block;
    }
  }

  .navigation-menu ul {
    display: none !important;
    position: absolute;
    z-index: 2;
    top: 70px;
    left: 0;

    flex-direction: column;

    width: 100%;
    //height: calc(100vh - 77px);
    background-color: $primary-color;
    border-top: 1px solid black;

    a {
      li {
        color: white;
        .profile_wrapper {
          display: none;
        }
        .profile_wrapper-shown {
          width: 300px;
          display: flex;
          flex-direction: column;
          background-color: #d9d9d9;
          position: absolute;
          // height: 100%;
          z-index: 100;
          top: 10px;
          right: 20px;
        }
      }
    }
  }

  .navigation-menu.expanded ul {
    display: block !important;
  }
}
@keyframes spring {
  15% {
    -webkit-transform-origin: center center;
    -webkit-transform: scale(1.2, 1.1);
  }
  40% {
    -webkit-transform-origin: center center;
    -webkit-transform: scale(0.95, 0.95);
  }
  75% {
    -webkit-transform-origin: center center;
    -webkit-transform: scale(1.05, 1);
  }
  100% {
    -webkit-transform-origin: center center;
    -webkit-transform: scale(1, 1);
  }
}
