@import "./variables";
.swiper {
  width: 100%;
  height: 70%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  //background: #fff;
  position: relative;

  /* Center slide text vertically */
  .card {
    display: flex;
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    color: white;

    .left {
      height: 100%;
      width: 50%;

      margin-left: 30px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;

      //font-family: Inter;
      //font-size: 120px;
      .card-heading-main {
        color: #000000;
        text-align: left;
        font-size: 30px;
        font-weight: bolder;
      }
      .card-heading {
        color: #000000;
        text-align: left;
      }
    }
    .right {
      height: 100%;
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-right: 30px;

      align-items: center;
      align-items: flex-start;
      .card-heading-main {
        color: #000000;
        align-self: flex-end;
        font-size: 30px;
        font-weight: bolder;
      }
      .card-heading {
        color: #000000;
        text-align: justify;
        align-self: flex-end;
      }
      button {
        align-self: flex-end;
      }
    }
  }
  img {
    display: block;
    position: relative;
    object-fit: cover;
    width: 100%;
    height: 400px;
    // object-fit: fill;
  }
}

.prepration {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 30px 80px;
  //font-family: Inter;
  .prepration__section {
    width: 100%;
    margin: 32px 70px 30px 70px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    //text-align: justify;
    gap: 20px;
    &-text {
      margin-top: 10px;
      line-height: 1.5rem;
      text-align: justify;
    }
  }
}
.our-line {
  background-color: #b4a9a9e5;
  height: 1px;
  margin: 0 140px;
}
.features__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px;
  .features {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    width: 900px;

    //background-color: aquamarine;
    &-text {
      // font-family: Inter;
      font-size: 40px;
      font-weight: 800;
      margin-bottom: 20px;
      margin-top: 20px;
    }
    &__section {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 10px;
      margin-bottom: 10px;

      &-left {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 20px 50px 20px 50px;
        svg {
          width: 100px;
        }
        img {
          width: 100px;
        }
      }
      &-right {
        // font-family: Inter;
        h3 {
          margin-bottom: 6px;
        }
      }
    }
  }
}

.pricingPlans__wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  gap: 30px;
  margin-top: 30px;
  .pricing_plans-left {
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    // align-items: center;
    margin-left: 30px;
    flex-wrap: wrap;
    .plan_heading {
      margin-left: 50px;
      margin-bottom: 20px;
    }
    .plans {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      margin-left: 50px;
      margin-right: 50px;
      //overflow: scroll;
    }
  }
  .pricing_plans {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 12px;
    .plan_heading {
      display: block;
      justify-content: center;
      align-items: center;
    }
    .plans {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      padding-left: 50px;
      padding-right: 50px;
      //overflow: scroll;
    }
  }
}
.subscribed-page-design {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.goToPlan {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  height: 90vh;
  margin-bottom: 20px;
  .cancel-plan-heading-main {
    padding-left: 36px;
    padding-top: 16px;
    padding-bottom: 16px;
    display: flex;
    justify-content: center;
  }
  .cancel-plan-button {
    width: 100%;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 100px;
    padding-right: 120px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .subscribed-plans {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 90%;
    gap: 30px;
    // margin-top: 30px;
    margin-left: 30px;
    overflow-y: scroll;
    //margin-right: 30px;

    .single-subscription {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 20px;
      width: 100%;
      color: white;
      background: linear-gradient(
        90deg,
        rgba(18, 17, 36, 1) 0%,
        rgba(79, 68, 74, 1) 48%,
        rgba(12, 33, 37, 1) 86%
      );

      border-radius: 10px;
      & > * {
        flex: 1 1 0px;
      }
      .manage-btn {
        padding: 10px;
        margin-left: 5px;
        width: 100px;

        &:hover {
          background: linear-gradient(
            90deg,
            rgb(12, 11, 24) 0%,
            rgb(43, 37, 40) 48%,
            rgb(8, 24, 28) 86%
          );
        }
      }
    }
    .single-purchsed {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 20px;
      width: 100%;
      color: white;
      background: linear-gradient(
        90deg,
        rgba(18, 17, 36, 1) 0%,
        rgba(79, 68, 74, 1) 48%,
        rgba(12, 33, 37, 1) 86%
      );

      border-radius: 10px;
      .manage-btn {
        padding: 10px;
        margin-left: 5px;
        width: 100px;
        background-color: rgba($color: #342b2b, $alpha: 1);

        &:hover {
          background: linear-gradient(
            90deg,
            rgb(12, 11, 24) 0%,
            rgb(43, 37, 40) 48%,
            rgb(8, 24, 28) 86%
          );
        }
      }
    }
  }
}
