@import "./variables";
.my_action_btn {
  color: white;
  background-color: $primary_color;
  --ring-color: #93c5fd;
  font-weight: 500;
  border-radius: 0.5rem;
  font-size: 0.8rem;
  line-height: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;
  text-align: center;
  margin-right: 0.5rem;
  display: inline-flex;
  align-items: center;
  border: none;
  text-decoration: none;
  margin-bottom: 5px;
  &:hover {
    background-color: $secondary_color;
  }

  &:focus {
    box-shadow: var(--tw-ring-inset) 0 0 0
      calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  }
  & svg {
    display: inline;
    width: 1.2rem;
    height: 1.2rem;
    margin-right: 0.5rem;
    color: white;
    // animation: spin_357 1s linear infinite;
  }
}

@keyframes spin_357 {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
