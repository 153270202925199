.footer-wrapper {
  background-color: #000000;
  padding-bottom: 20px;
  height: 100%;
  .footer-card {
    margin-top: 30px;
    width: 100%;

    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 25px 25px 16px 25px;
    gap: 20px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.055);
    img {
      width: 32px;
      height: 32px;
      border-radius: 6px;
    }
  }
  .reserved {
    width: 100%;
    height: fit-content;
    color: rgb(238, 238, 238);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 10px 16px 10px;
    //gap: 20px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.055);
  }
  .footer-link {
    width: 100%;
    height: fit-content;

    display: flex;
    align-items: center;
    justify-content: center;
    .left {
      border-right: 1px solid white;
      padding-right: 20px;
      margin-right: 20px;
    }
    a {
      color: white;
      text-decoration: none;
    }
  }
}
