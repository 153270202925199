@import "./variables";
.group {
  display: flex;
  line-height: 28px;
  align-items: center;
  position: relative;
  max-width: 500px;
  width: 100%;
  margin-top: 20px;
  .input {
    width: 100%;
    height: 60px;
    line-height: 28px;
    padding: 0.5rem 1rem;
    padding-left: 3rem;
    border: 2px solid transparent;
    border-radius: 8px;
    outline: none;
    background-color: #f3f3f4;
    color: #0d0c22;
    transition: 0.3s ease;

    &::placeholder {
      color: #9e9ea7;
    }
    &:focus,
    &:hover {
      outline: none;
      border-color: $primary_color;
      background-color: #fff;
      box-shadow: 0 0 0 4px rgb(234 76 137 / 10%);
    }
  }
  .icon {
    position: absolute;
    left: 1rem;
    fill: #9e9ea7;
    width: 1.5rem;
    height: 1.5rem;
  }
}
