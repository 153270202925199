.model_wrapper {
  width: 100%;
  height: 100%;
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  background-color: white;
  z-index: 1000;
  animation: popout 1s ease;
  -webkit-animation: popout 1s ease;
  overflow-y: scroll;
  svg {
    position: fixed;
    align-self: flex-end;
    margin-top: 20px;
    margin-right: 20px;
  }
  .model {
    width: 100%;
    height: 100%;
  }
}
@keyframes popout {
  from {
    transform: scale(0);
  }
  80% {
    transform: scale(1.2);
  }
  to {
    transform: scale(1);
  }
}
@-webkit-keyframes popout {
  from {
    -webkit-transform: scale(0);
  }
  80% {
    -webkit-transform: scale(1.2);
  }
  to {
    -webkit-transform: scale(1);
  }
}

.show {
  display: flex;
  flex-direction: column;
}
