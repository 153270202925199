.Yellow-btn {
  background-color: #ffc736;
  width: fit-content;
  transition: all ease-in-out 0.2s;
  color: black;
}
.full-width {
  width: 90%;
}

.Yellow-btn:hover {
  letter-spacing: 0.3px;
  background-color: #ffc736;
  color: hsl(0, 0%, 100%);
  box-shadow: #ffc736 0px 7px 29px 0px;
}
